export default  [
    {
        id: 1,
        name:"regular bird",
        birdType:1,
        birdImg:"regular_bird_copy.jpg",
        price:"1 xrp",
        ethPrice:"0.01 eth",
        infoHeader:"regular bird",
        infoDescription:"just bird",
    },

    {
        id: 2,
        name:"nerd bird",
        birdType:2,
        birdImg:"nerd_bird_copy.jpg",
        price:"10 xrp",
        ethPrice:"0.1 eth",
        infoHeader:"nerd bird",
        infoDescription:"glasses",
    },
    {
        id: 3,
        name:"cool bird",
        birdType:3,
        birdImg:"cool_bird_copy.jpg",
        price:"100 xrp",
        ethPrice:"1 eth",
        infoHeader:"cool bird",
        infoDescription:"sunglasses",
    },
    {
        id: 4,
        name:"babe bird",
        birdType:4,
        birdImg:"babe_bird_copy.jpg",
        price:"1 000 xrp",
        ethPrice:"10 eth",
        infoHeader:"babe bird",
        infoDescription:"color",
    },
    {
        id: 5,
        name:"sir bird",
        birdType:5,
        birdImg:"sir_bird_copy.jpg",
        price:"10 000 xrp",
        ethPrice:"100 eth",
        infoHeader:"sir bird",
        infoDescription:"top class",
    },
    {
        id: 6,
        name:"almighty bird",
        birdType:6,
        birdImg:"almighty_bird_copy.jpg",
        price:"100 000 xrp",
        ethPrice:"xrpl legacy: 2nd first",
        infoHeader:"the one and only \n almighty bird!",
        infoDescription:"The second first ever minted NFT with picture on XRPL. \n All birds are a part of this legacy. \n (Validate on ledger index: 75443459)",
    }


]