// ethAndMetamask.js
import { useState, useEffect } from "react";
import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from 'ethers';
import MetaMaskSDK from '@metamask/sdk';

const options = {
  dappMetadata: { name: "My Dapp", url: "https://mydapp.com" }, // replace with your dapp name and url
  injectProvider: true,
  forceInjectProvider: false,
  forceDeleteProvider: false,
  checkInstallationImmediately: false,
  checkInstallationOnAllCalls: false,
  shouldShimWeb3: true,
  preferDesktop: true
};

const MMSDK = new MetaMaskSDK(options);
const ethereum = window.ethereum; // You can also access via window.ethereum

const contractData = require("../abi.json");
const contractAbi = contractData.abi;
const contractAddress = "0x55deF676Ac8AF3141d2Efa73737fcBAF06Ba459d";



export function useMetaMask() {
  const [account, setAccount] = useState();
  const [provider, setProvider] = useState();
  const [contractInstance, setContract] = useState();
  const [isConnected, setIsConnected] = useState(false);
  const [isMintSigned, setIsMintSigned] = useState(false);
  const [mintStatus, setMintStatus] = useState("🔴 not connected");

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  

  const checkChainId = async (detectedProvider) => {
    const chainId = await detectedProvider.request({ method: 'eth_chainId' });
    if(chainId !== '0x13881') { // For Ethereum Mainnet
      console.log('Please switch to the Ethereum Mainnet');
      console.log(chainId)
      return false;
    }
    return true;
}

const handleChainChanged = (_chainId) => {
    const hexChainId = parseInt(_chainId, 16);
    if (hexChainId !== 1) { // Ethereum Mainnet
        console.log('Please switch to the Ethereum Mainnet');
    } else {
        window.location.reload();
    }
};

  const typedData = {
    types: {
      EIP712Domain: [
         { name: "name", type: "string" },
    { name: "version", type: "string" },
    { name: "chainId", type: "uint256" },
    { name: "verifyingContract", type: "address" },
      ],
      Message: [
        { name: "message", type: "string" },
      ],
    },
    primaryType: "Message",
    domain: {
      name: "LAPP", // Replace with your Dapp name
      version: "1", // Version
      chainId: 80001, // Mainnet
      verifyingContract: contractAddress, // Your contract address
    },
    message: {
      message: "Hello, please sign this message to mint NFT.",
    },
  };

  useEffect(() => {
    const checkMetaMaskConnection = async () => {
      const detectedProvider = await detectEthereumProvider();
      if (!detectedProvider) {
        console.log('Please install MetaMask!');
        return;
      }

      if (!checkChainId(detectedProvider)) {
        return;
      }

      const ethersProvider = new ethers.providers.Web3Provider(detectedProvider);
      const signer = ethersProvider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractAbi, signer);

      setProvider(ethersProvider);  // update state
      setContract(contract); // update state

      const accounts = await detectedProvider.request({ method: 'eth_accounts' });
      if (accounts && accounts.length > 0) {
        setAccount(accounts[0]);
        setIsConnected(true);
        setMintStatus("🟢 connected")
      } else {
        setIsConnected(false);
        setMintStatus("🔴 not connected")
      }

      ethereum.on("accountsChanged", handleAccountsChanged);
      ethereum.on("chainChanged", handleChainChanged);
    };

    const handleAccountsChanged = (accounts) => {
      if (accounts && accounts.length > 0) {
        setAccount(accounts[0]);
        setIsConnected(true);
        setMintStatus("🟢 connected")
      } else {
        setAccount(null);
        setIsConnected(false);
        setMintStatus("🔴 not connected")
      }
    };

    const handleChainChanged = (_chainId) => {
      window.location.reload();
    };

    checkMetaMaskConnection();

    return () => {
      if (ethereum) {
        ethereum.removeListener("accountsChanged", handleAccountsChanged);
        ethereum.removeListener("chainChanged", handleChainChanged);
      }
    };
  }, []);

  async function connectToMetaMask() {
    const detectedProvider = await detectEthereumProvider();
    if (!detectedProvider) {
      console.log('Please install MetaMask!');
      return;
    }
    if (!checkChainId(detectedProvider)) {
        return;
    }

    // Create provider and signer immediately after detection
    const ethersProvider = new ethers.providers.Web3Provider(detectedProvider);
    const signer = ethersProvider.getSigner();
    const contract = new ethers.Contract(contractAddress, contractAbi, signer);

    setProvider(ethersProvider);  // update state
    setContract(contract); // update state

    try {
      const accounts = await detectedProvider.request({ method: 'eth_requestAccounts' });
      if (accounts && accounts.length > 0) {
        setAccount(accounts[0]);
        setIsConnected(true);
        setMintStatus("🟢 connected")
      } else {
        setMintStatus("🔴 not connected");
        console.log('Please connect to MetaMask.');
      }
    } catch (error) {
      if (error.code === 4001) {
        // EIP-1193 userRejectedRequest error
        console.log('Please connect to MetaMask.');
      } else {
        console.error(error);
      }
    }
  }

  async function mintNFT(typeIndex) {
    console.log("clicked");
    console.log("Provider:", provider);
    console.log("Contract Instance:", contractInstance);
    console.log("Account:", account);
    
  
    if (!provider || !contractInstance || !account) {
      console.log('After check, Contract Instance:', contractInstance);
      return;
    }
  
    console.log("clicked2");

    
    // Send a dummy request to open the MetaMask app
   

    if (isMobile) {
        // Send a dummy request to open the MetaMask app
        try {
            window.open('https://metamask.app.link/connect', '_blank');
        } catch (error) {
            console.error('Error with dummy request:', error);
            return;
        }
    }


    // Introduce a delay before proceeding with the mint
  
  
    // Determine price based on typeIndex
    let price;
    switch (typeIndex) {
      case 0:
        price = ethers.utils.parseUnits("0.01", "ether");
        break;
      case 1:
        price = ethers.utils.parseUnits("0.1", "ether");
        break;
      case 2:
        price = ethers.utils.parseUnits("1", "ether");
        break;
      case 3:
        price = ethers.utils.parseUnits("10", "ether");
        break;
      case 4:
        price = ethers.utils.parseUnits("100", "ether");
        break;
      default:
        console.log("Invalid type");
        return;
    }

    let estimatedGasLimit;
    try {
        estimatedGasLimit = await contractInstance.estimateGas.mint(account, typeIndex, { value: price });
    } catch (error) {
        console.error('Error estimating gas limit: ', error);
        return;
    }

    let gasPrice;
    try {
        gasPrice = await provider.getGasPrice();
    } catch (error) {
        console.error('Error fetching gas price: ', error);
        return;
    }
  
    try {
      console.log("Type Index:", typeIndex);
      console.log("Price:", price.toString());
      console.log('About to send transaction');
      if(!isMobile){
        setMintStatus("🟢 waiting for sign...")
    }
      console.log('Mint initiated');
      let tx = await contractInstance.mint(account, typeIndex, {
        value: price,
        gasLimit: estimatedGasLimit,
        gasPrice: gasPrice
      });

      setIsMintSigned(true); // update state
      if(!isMobile){
      setMintStatus('🟢 transaction sent...🤞');
      }
      console.log('Transaction sent: ', tx.hash);
      await tx.wait();
      if(!isMobile){
      setMintStatus('🟢🎉 mint complete. welcome to birdjpg!🙏❤️');
      }
      console.log('Transaction mined');
    } catch (error) {
        if(error.code===4001){
            setMintStatus('rejected💔');
        }
        else{
            setMintStatus('❌ Error, please try again (sometimes MetaMask needs to be restarted due to sync & security): ' + error);
        }
        
      console.log('Error: ', error);
    }
  }


  return {
    account,
    provider,
    contractInstance,
    isConnected,
    isMintSigned,
    mintStatus,
    isMobile,
    connectToMetaMask,
    mintNFT
  };
}