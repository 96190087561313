import React , {useState, useEffect} from "react";

export default function Whitepaper (props){


    return(<>
    
    <div className={props.closing ? "whitepaper closing" : "whitepaper"}>
      
        <div className="whitepapercontent">
        <a className="whitepaperclose"
            onClick={() => props.handleCloseWhitepaper()}>
                CLOSE
            </a>
           
           
        <div className="whitepaperheader">birdjpg</div>
        <b>what you get:</b> bird <br />
        <b>promise:</b> no <br />
        <br />
        <b>official birdjpg:</b><br />
        <b>twitter:</b> <a className="smallgoldtext"><a className="whitepaperbtns" href="https://twitter.com/birdjpg_io" target="_blank" rel="noopener noreferrer">@birdjpg_io </a></a><br />
        <b>website:</b> <a className="smallgoldtext"><a className="whitepaperbtns" href="https://birdjpg.io" rel="noopener noreferrer">https://birdjpg.io</a></a><br />
        <b>marketplace eth:</b> <a className="smallgoldtext"><a className="whitepaperbtns" href="https://opensea.io/birdjpg" target="_blank" rel="noopener noreferrer">https://opensea.io/birdjpg</a></a> <br />
        <b>marketplace xrpl:</b> <a className="smallgoldtext"><a className="whitepaperbtns" href="https://xrp.cafe/collection/birdjpg" target="_blank" rel="noopener noreferrer">https://xrp.cafe/collection/birdjpg</a></a> <br />
        
        <br />
     

        <b><a className="smallgoldtext">"almighty bird"</a></b> is the <b><a className="whitepaperbtns" href="https://bithomp.com/ledger/75443459" target="_blank" rel="noopener noreferrer">second first ever minted NFT</a></b> (with uri) on the XRPL. <br />
       
        
        < br />
       total birdjpg on Ethereum:<b> 800 </b>- controlled by smart contract: <a className="whitepaperbtns" href="https://etherscan.io/address/0x55def676ac8af3141d2efa73737fcbaf06ba459d" target="_blank" rel="noopener noreferrer">0x55def676ac8af3141d2efa73737fcbaf06ba459d</a> <br/>

       <br />
       <br />
       total birdjpg on xrpl: <b>7208</b><br /><br />
       when birds migrate - birds are burned on xrpl and moved to other chains. supply will never increase.
       <br /><br /><span className="middletext">- get bird<br /><br /></span>
        </div>
            </div>
    
    </>)
}