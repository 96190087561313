import React, { useMemo, memo } from "react";
import { useState, useEffect } from 'react';
import birdBoxData from "./birdBoxData";
import BirdBox from "./BirdBox"
import { createPortal } from 'react-dom';
import { ShopWindow } from "./ShopWindow";
import GetTotalBirds from "./countBirds"

export default function GenerateBoxes(props){
  
    const [totals, setTotals] = useState();
  
    const extractedIds = birdBoxData.map(obj => parseInt(obj.id));

    useEffect(() =>{
    GetTotalBirds(extractedIds)
    .then(response => {
      setTotals(response)
      //console.log("DONE" + totals)
    })
    .catch(error => {
      // Handle any errors that occurred during the AJAX call
      console.log("DONE" + error);
    });
}, [])


    const [renderShop, setRenderShop] = useState(null)
    const [exitShopAnimation, setExitShopAnimation] = useState(null)
    const [renderBirdName, setRenderBirdName] = useState(null)
    const [renderBirdId, setRenderBirdId] = useState(null)
    const [renderBirdImg, setRenderBirdImg] = useState(null)
    const [renderBirdPrice, setRenderBirdPrice] = useState(null)
  

    const handleClose = () => {

        
        
        setExitShopAnimation(true);
    }


    useEffect(() => {
        if (exitShopAnimation) {
          const timer = setTimeout(() => {
            setRenderShop(null);
            setExitShopAnimation(null)
            
          }, 500); // Adjust the timeout to match the duration of the exit animation
    
          return () => {
            clearTimeout(timer);
          };
        }
      }, [exitShopAnimation]);

      const [birdData, setBirdData] = useState(null);

      const handleButtonClick = (birdid, birdname, birdimg, xrplPrice, ethPrice) => {
        setBirdData({
            id: birdid,
            name: birdname,
            img: birdimg,
            xrplPrice: xrplPrice,
            ethPrice: ethPrice,
        });
    
        setRenderShop(document.getElementById('birdid' + birdid));
        setRenderBirdName(birdname)
        setRenderBirdId(birdid)
        setRenderBirdImg(birdimg)
    }

    useEffect(() => {
      if (birdData) {
          setRenderBirdPrice(props.chain === 'xrpl' ? birdData.xrplPrice : birdData.ethPrice);
      }
  }, [birdData, props.chain]);
   

    const birdBoxes = () => {
        if(!totals){
           
            return (<p>loading..</p>)
           
        }
        
        else{
           
            return(
        birdBoxData.map((data) => (
        <BirdBox
          key={data.id}
          id={data.id}
          name={data.name}
          birdImg={data.birdImg}
          price={props.chain === "xrpl" ? data.price : (props.chain === "eth" ? data.ethPrice : data.price)}
          birdtype={data.birdType}
          boxClick={handleButtonClick.bind(null, data.id, data.name, data.birdImg, data.price, data.ethPrice)}
          totalBirds={totals ?  totals[data.id][props.chain].unsold + " / " + totals[data.id][props.chain].total  : "loading..."}
          totalAllChains={totals ? (totals[data.id]["xrpl"].total+ totals[data.id]["eth"].total) :"" }
          infoHeader={data.infoHeader}
          infoDescription={data.infoDescription}
          supplyEth={totals[data.id]["eth"].total}
          supplyXrpl={totals[data.id]["xrpl"].total}
          unsoldChain={totals[data.id][props.chain].unsold}
          chain={props.chain}
          
        />
      )))
    }
}




 return(

   <>
   <div className={props.visibilityStatus === true ? "" : "hidden"}>
   {birdBoxes()}

{renderShop ? createPortal(
<ShopWindow 
    key={props.chain}
    birdid={renderBirdId} 
    birdname ={renderBirdName}
    birdImg = {renderBirdImg}
    handleClose = {handleClose}
    exitShopAnimation = {exitShopAnimation}
    chain = {props.chain}
    birdPrice={renderBirdPrice}
    

/>, renderShop) : ""}

   

</div>
    </>
    

 )


}
