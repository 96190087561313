
import React, {memo, useState, useEffect} from "react"


 const BirdBox = React.memo((props) => {

    const [showInfo, setShowInfo] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [previousHovered, setPreviousHovered] = useState(null);

   
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleInfo = () => {
        if(isMobile) {
            setShowInfo(!showInfo)
        } else {
            setShowInfo(true);
        }
    }
    
return(

<div className="birddiv">

<div className="shopholder" id={"birdid"+props.id}></div>
<div className={"nftholder " + props.name}
onMouseEnter={() => {
    if(previousHovered === "infoButton") setShowInfo(true);
  }}
  onMouseLeave={() => {
    setPreviousHovered(null);
    setShowInfo(false);
  }}


>
<img className="birdimg" src={require(`../images/${props.birdImg}`)} alt="nan" /> <br />
<span key={props.chain} className={"birdsleft "+props.chain}>{props.totalBirds}</span><br />
<span className="birdslefttotal">{"( "+ props.totalAllChains +" )"}</span><br />

<div className="infoWrapper"
        onMouseEnter={() => setShowInfo(true)}
        onMouseLeave={() => setShowInfo(false)}
      >
        <div className={showInfo ? "infoText show" : "infoText hide"}>
          <div className="infoBackground">

          
         <h2 className="infoheader">{props.infoHeader.split('\n').map((line, i) => (
  <span key={i}>
    {line}
    <br />
  </span>
))}
</h2>
<div className="infoDescription">
{props.infoDescription.split('\n').map((line, i) => (
  <span key={i}>
    {line}
    <br />
  </span>
))}

</div>

<div className="infoSupply">
<div>supply:</div>
  <div className="row">
    
    
  </div>
  <div className="row">
    <div className="label">eth:</div>
    <div className="number">{props.supplyEth}</div>
  </div>
  <div className="row">
    <div className="label">xrpl:</div>
    <div className="number">{props.supplyXrpl}</div>
  </div>
  <div className="row">
    <div className="label">total:</div>
    <div className="number">{props.totalAllChains}</div>
  </div>
</div>

          </div>
        </div>
        
        <div 
  className="infoButton"
  onMouseEnter={() => setPreviousHovered("infoButton")}
  onClick={toggleInfo}
>
  <span className="wall-i">(</span><span className="the-i">i</span><span className="wall-i">)</span>
</div>
      </div>
</div>



<button 
    className={
        (props.id > 5 && props.chain === "eth") || 
        (props.unsoldChain === "0") || 
        (props.unsoldChain === "0")
        ? "birdbutton disabled" 
        : "birdbutton"
    }
    disabled={
      (props.id > 5 && props.chain === "eth") || 
      (props.unsoldChain === "0") || 
      (props.unsoldChain === "0")
    }
    onClick={() => props.boxClick(props.id, props.name, props.birdImg, props.price)}
> 

{props.name ? props.name : "error loading name" }<br />
<span className="smallbtntext">{props.price ? props.price : "error loading price" }{props.unsoldChain ==="0" && props.chain==="xrpl" ? " - sold out!" : ""}</span> 

</button>



<br /><br /><br />



</div>


)
})

export default memo(BirdBox)