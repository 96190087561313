import React, { useEffect, useState } from "react";
import FanArt from "./FanArt";

function Events(props) {

    return(<>
    
   <div className="noevents"> <p>no public events at the moment</p> </div>
    </>)
}
export default Events;