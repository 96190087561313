import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import { useMetaMask } from "./ethAndMetamask";
import postOffer from "./postOffer";

export function ShopWindow(props) {
  const [timerController, setTimerController] = useState();
  const staticShopImage = require(`../images/${props.birdImg}`);
  const [imageUrl, setImageUrl] = useState(staticShopImage);
  const [inputWallet, setInputWallet] = useState({ currentVal: "" });
  const [inputStatus, setInputStatus] = useState(false);
  const [stateController, setStateController] = useState(0);
  const [qrURL, setQRURL] = useState();

  const {
    account,
    provider,
    contractInstance,
    isConnected,
    isMintSigned,
    mintStatus,
    isMobile,
    connectToMetaMask,
    mintNFT
  } = useMetaMask();

  

  useEffect(() => {
    if (props.chain === "eth") {
      setStateController(3);
      
    }
    if (props.chain === "xrpl") {
      checkForUpdates();
    }
  }, [props.chain, stateController]);

  useEffect(() => {
    const fetchData = async () => {
      checkForUpdates();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (globalWallet !== null) {
      setInputWallet({ currentVal: globalWallet });
      checkForUpdates();
    }
  }, []);

  const timerRef = useRef(null);

  useEffect(() => {
    timerRef.current = setInterval(() => {

      if(timerController ==="firstorder" || timerController==="expired" ){
        setStateController(0)
      }

      if (
        timerController !== "firstorder" &&
        timerController !== "expired" &&
        props.chain === "xrpl"
      ) {
        checkForUpdates();
      }
    }, 3000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [timerController]);

  function shopStateHandler(shopState) {
    if (props.chain === "xrpl") {
      if (shopState === 0) {
        // Idle state, waiting for user inputs
        setStateController(0);
        setImageUrl(staticShopImage);
        setInputStatus(false);
      }
      if (shopState === 1) {
        // Generating state, waiting for external services
        setStateController(1);
        setImageUrl(staticShopImage);
        setInputStatus(true);
      }
      if (shopState === 2) {
        // Active state, waiting for user purchase
        setStateController(2);
        setInputStatus(true);
      }
    }
  }

  const globalWallet = localStorage.getItem("inputWallet");

  const walletInputHandler = (event) => {
    const { name, value } = event.target;
    setInputWallet((prevInputWallet) => ({
      ...prevInputWallet,
      [name]: value,
    }));
    localStorage.setItem("inputWallet", value);
  };

  function checkForUpdates() {
   
    if (props.chain === "xrpl") {
      $.ajax({
        url: "getqr.php",
        type: "POST",
        data: {
          action: "getQR",
          birdSelector: props.birdid,
          customerWalletAdress: globalWallet,
        },
        success: function (response) {
          const parsedResponse = JSON.parse(response);
          setTimerController(parsedResponse);
        

          if (parsedResponse === "loading") {
            shopStateHandler(1);
          } else if (parsedResponse.includes("https://xum")) {
            setQRURL(parsedResponse);
            shopStateHandler(2);
          } else if (parsedResponse === "sold") {
            shopStateHandler(0);
          } else if (parsedResponse === "expired") {
            shopStateHandler(0);
          }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          shopStateHandler(0);
          console.log(errorThrown)
        },
      });
    }
  }

  const mintNFTHandler = (typeIndex) => {
    mintNFT(typeIndex);
  };

  

  // Render the ShopWindow component
  return ( <>

  
    <div className={props.exitShopAnimation ? `shopWindow closing ${props.birdname}` : `shopWindow ${props.birdname}`}>
      <a className="closeshop" onClick={() => props.handleClose()}> x </a>
      
    
{stateController===0 && <> <div className="spinner">
  <div className="bird"><img
        className="birdshopimg"
        src={imageUrl}
        alt="nan" /></div>
</div></>}


{stateController===0 && <h1 className="shopheader">get {props.birdname}</h1> }





      {stateController === 0 && (
        <>
        <div className="placeholdershop">
        {globalWallet && window.xrpl.isValidAddress(globalWallet) ? (
          <span className="walletvalidator">✅ valid xrpl-wallet address</span>
        ) : (
          globalWallet && <span className="walletvalidator">❌ invalid xrpl-wallet address<br /></span>
        )}
        <input
          className="walletinput"
          name="currentVal"
          placeholder="enter XRP Wallet Adress"
          value={inputWallet ? inputWallet.currentVal : ""}
          onChange={walletInputHandler}
          disabled={inputStatus}
        />
        <button
          className="birdbutton shop"
          onClick={() => {
            if(window.xrpl.isValidAddress(globalWallet)){
              setTimerController(postOffer(props.birdid, globalWallet));
              setTimeout(() => {
                checkForUpdates();
              }, 2000);
              setInputStatus(true);
              setStateController(1);
            }
            else{
              
            }
          }}
        ><span>
          {props.birdid
            ? "Generate QR for "
            : "error loading name"}
          <br />
          {props.birdid ? props.birdname : "error loading name"}
          <br />
          </span>
        </button>
        </div>
        </>
      )}




{stateController===1 && (
        <> 
        <div className="spinner">
        <div className="bird loading">
          <img
                className="birdshopimg"
                src={imageUrl}
                alt="nan" ></img>      
        </div>
        </div>
                <h1 className="shoptext">
                  generating <br /> <a className="goldtext"> {props.birdname} </a><br />
                </h1>
          </>
      )}

{stateController===2 && (
      <>
      <br />
      <br />
      <img
        className="birdshopimg"
        src={qrURL}
        alt="nan"
        onClick={
          () => window.open(qrURL.slice(0, -6), "_blank")
        }
      />  
        <p className="shoptext">
          <a className="smallet">
            <i>tap to open in XUMM or scan QR code with your XUMM app</i>{" "}
          </a>
          <br />
          
          <a className="goldtext">{props.birdname} is ready. </a>
          </p>
          <div className="spinner">
          <div className="bird">
          <img
        className="smallbirdshopimg"
        src={imageUrl}
        alt="nan" />
          </div>
          
          </div>
          </>
       
      )}



{stateController===3 && (<>
  <div className="spinner">
    <div className="bird"><img className="birdshopimg" src={imageUrl} alt="nan" /></div>
  </div>
  <h1 className="shopheader">get {props.birdname}</h1>
  <span className="mintstatus">{mintStatus}</span>
  <div className="placeholdershop">
  <button
    className={props.birdid>5 && props.chain==="eth" ? "birdbutton shop disabled" : "birdbutton shop"}
    onClick={() => isConnected && props.chain==="eth" && props.birdid<6 ? mintNFT(props.birdid-1) : connectToMetaMask()}
  >
   <span> {props.chain === "eth" && props.birdid<6 ? 
    (isConnected ?  
      <>mint for <br /> {props.birdPrice} </> 
      : 
      "Connect to MetaMask") 
    : 
    "bird is not available on " + props.chain
  }</span>
  </button>
  </div>
</>)}
        
    </div>
  </>






);
        }