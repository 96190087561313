import $ from "jquery"
import React , {useState} from "react";

export default function GetTotalBirds(birdNum) {

   
    return new Promise(async (resolve, reject) => {
    

$.ajax({
    type: "POST",
    url: "gettotalbirds.php",
    data: {
      action :'getTotalBirds',
      birdselector: birdNum,
      
    },
    success: function(data) {
        //console.log(data)
      //data = JSON.parse(data.total[0])
     
      resolve(JSON.parse(data))
      console.log(data)
      //return(totalbirds)
    

    },
    error: function(error) {
      console.error(error);
      reject(error)
    }
  });




   
return(
    <>
    
    </>
    
    )
      
   
})}
     
