import React , {useState, useEffect} from "react";
import { render } from "react-dom";
import GenerateBoxes from "./BirdBoxes"
import Whitepaper from "./Whitepaper";
import Events from "./Events";




export default function Content() {
 

    const [wrapperAnimation, setWrapperAnimation] = useState(null)
    const [renderWhitepaper, setRenderWhitepaper] = useState(null)
    const [exitWhitepaperAnimation, setExitWhitepaperAnimation] = useState(null)
    const [renderEvents, setRenderEvents] = useState(false)
    const [currentChain, setCurrentChain] = useState("xrpl")



 

  const handleChangeChain = async (chain) => {
    setCurrentChain(chain)
  }


  

    useEffect(() => {
        // Check for the 'get' query parameter on component mount
        const searchParams = new URLSearchParams(window.location.search);
        const show = searchParams.get("get");
        console.log(show)
      
        if (show === "xrpl-coffee") {
          // Add a 2-second delay before setting the wrapperAnimation to "in"
          const timer = setTimeout(() => {
            handleOpenEvents();
        setCurrentChain("xrpl")
          }, 800); // 2000 milliseconds (2 seconds)
      
          // Clean up the timer when the component unmounts
          return () => {
            clearTimeout(timer);
          };
        }
      }, []);


    const handleOpenEvents = () => {

        
        setWrapperAnimation("in")
        console.log(wrapperAnimation)
        
    }
   




    useEffect(() => {
        if (wrapperAnimation) {
          const timer = setTimeout(() => {
            setWrapperAnimation("out")
            
            console.log("renderEvents is :  " + renderEvents)
            
          }, 500); // Adjust the timeout to match the duration of the exit animation
    
          return () => {
            setRenderEvents(!renderEvents)
            clearTimeout(timer);
            
          };
        }
      }, [wrapperAnimation]);

    const handleCloseWhitepaper = () => {
        setExitWhitepaperAnimation(true);
        console.log("LOGGED")
    }

    const handleOpenWhitepaper = () => {
        setRenderWhitepaper(true);
        console.log("APA")
    }
    
    useEffect(() => {
        if (exitWhitepaperAnimation) {
          const timer = setTimeout(() => {
            setRenderWhitepaper(null);
            setExitWhitepaperAnimation(null)
            
          }, 500); // Adjust the timeout to match the duration of the exit animation
    
          return () => {
            clearTimeout(timer);
          };
        }
      }, [exitWhitepaperAnimation]);

return(
    <>


    
<div id="wrapper" className={
    (wrapperAnimation ==="in" ? "bannerheader wrapperin" :"")  || 
    (wrapperAnimation ==="out" ? "bannerheader wrapperout" : "") ||
    (!wrapperAnimation && "bannerheader")
    }>
    <div className="eventsbtn">
        <a onClick={handleOpenEvents}>{renderEvents === false ? "events" : "back" }</a>
    </div>
 
 
   
    <a className="discordbtn" href="https://discord.gg/uP56TahB7a" target="_blank" rel="noopener noreferrer">discord</a>

    <a className="twitterbtn" href="https://www.twitter.com/birdjpg_io" target="_blank" rel="noopener noreferrer">twitter</a>
    
   
  
    <div className="whitepaperbtn">
        <a onClick={handleOpenWhitepaper}>whitepaper</a>
    </div>
</div>



<div id="wrapper" className={
    (wrapperAnimation ==="in" ? "wrapper wrapperin" :"")  || 
    (wrapperAnimation ==="out" ? "wrapper wrapperout" : "") ||
    (!wrapperAnimation && "wrapper")
    }>
    
    {renderWhitepaper && 
    <Whitepaper 
    handleCloseWhitepaper={handleCloseWhitepaper}
    closing={exitWhitepaperAnimation}
    
    />}

    
  

<div className="content">



    <div className="pickchain">

      <a className="pickchaintext">
      chain
      </a>
</div>

<div className="chainDiv">

  
{currentChain ==="xrpl" ? 
    <a className="chainname selected"
   onClick={() => {handleChangeChain("xrpl")}}
   >xrpl</a> : 
   
   
    <a className="chainname"
   onClick={() => {handleChangeChain("xrpl")}}
   >xrpl</a>  
   
   }

<span className="heart">❤️</span>

{currentChain ==="eth" ? 
    <a className="chainname selected"
   onClick={() => {handleChangeChain("eth")}}
   >eth</a> : 
   
   
    <a className="chainname"
   onClick={() => {handleChangeChain("eth")}}
   >eth</a>  
   
   }



 
</div>


{renderEvents === true ? <Events

currentChain = {currentChain}

/> :"" }
        <GenerateBoxes 
        
        visibilityStatus={renderEvents === true ? false : true}
        chain={currentChain}
        
            
       />



    </div>
    </div>
    </>


    )


}