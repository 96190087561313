import Header from "./components/Header"
import React , {useState} from "react";
import Content from "./components/Content";






function App() {

  return (
    
    <>
   
    <Header />
    <Content />

       
        
    </>
      );
}

export default App;
