import $ from "jquery"
import React , {useState} from "react";

export default function postOffer(birdid, customerWalletAddress) {
    $.ajax({
        type: "POST",
        url: "postoffer.php",
        data: {
          action :'postOffer',
          birdSelector: birdid,
          customerWalletAdress: customerWalletAddress
          
        },
        success: function(data) {
    
            console.log(data);
    
         
          
        },
        error: function(error) {
          console.error(error);
        }
      });
    



}